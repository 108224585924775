import { MOBILE_ROWS } from "./constants";

export default class MobileCardQueue {
  constructor(cardData, priorityCardData, cols) {
    this.cardDataArray = cardData;
    this.priorityCardDataArray = priorityCardData.reverse();

    this.priorityCardObj = this.priorityCardDataArray.reduce(
      (cardMap, currentCard) => ({
        ...cardMap,
        [currentCard.id]: true,
      }),
      {}
    );

    this.priorityCardIds = this.priorityCardDataArray.map((card) => card.id);

    this.cardDataObj = this.cardDataArray.reduce(
      (cardMap, currentCard) => ({
        ...cardMap,
        [currentCard.id]: currentCard,
      }),
      {}
    );
    this.currentIndex = 0;
    this.pendingPriorityViewed = true;
    this.cols = cols;
    this.carouselQueues = [];
    this.unload = false;
  }

  setInitialRows = (rows) => {
    this.carouselQueues = MOBILE_ROWS.map((row, idx) => {
      const r = [];
      if (rows.includes(idx)) {
        for (let i = 0; i < this.cols; i++) {
          const isCenter =
            idx === MOBILE_ROWS.length / 2 - 1 &&
            i === Math.ceil(this.cols / 2 - 1);
          const id = isCenter
            ? "center"
            : (this.priorityCardIds.length && this.getNewPriorityId()) ||
              this.getNewValidId(r, true);
          r.push(this.getCardById(id));
        }
      } else {
        for (let i = 0; i < this.cols; i++) {
          const id = this.getNewValidId(r, true);
          r.push(this.getCardById(id));
        }
      }

      return r;
    });
  };

  getCardById = (id) => {
    if (id === "center") {
      return {
        id: "center",
      };
    }
    return id && this.cardDataObj[id];
  };

  getNewCardId = () => {
    const nextCard = this.cardDataArray[this.currentIndex];
    this.currentIndex++;
    if (this.currentIndex === this.cardDataArray.length) {
      this.currentIndex = 0;
    }
    return nextCard.id;
  };

  getNewValidId = (row, initialLoad = false) => {
    if (!initialLoad && this.priorityCardIds.length) {
      return this.priorityCardIds.pop();
    } else {
      let id = null;
      const idArr = row.map((r) => r.id);
      while (id === null) {
        const newId = this.getNewCardId();
        if (!idArr.includes(newId)) {
          id = newId;
          break;
        }
      }

      return id;
    }
  };

  getNewPriorityId = () => {
    const id = this.priorityCardIds.pop();
    return id;
  };

  swipeRight = (row) => {
    const out = this.carouselQueues[row].pop();
    const newId = this.getNewValidId(this.carouselQueues[row]);
    if (!this.userHasSeenAllPriorityCards()) {
      this.verifyMiddle("right", this.carouselQueues[row]);
      this.checkCardStatus(out.id);
    }
    this.carouselQueues[row].unshift(this.getCardById(newId));
  };

  swipeLeft = (row) => {
    const out = this.carouselQueues[row].shift();
    const newId = this.getNewValidId(this.carouselQueues[row]);
    if (!this.userHasSeenAllPriorityCards()) {
      this.verifyMiddle("left", this.carouselQueues[row]);
      this.checkCardStatus(out.id);
    }
    this.carouselQueues[row].push(this.getCardById(newId));
  };

  checkCardStatus = (outId) => {
    if (!this.priorityCardObj[outId]) {
      this.priorityCardIds.push(outId);
    }
  };

  verifyMiddle = (direction, row) => {
    const id = direction === "left" ? row[0].id : row[row.length - 1].id;
    if (this.priorityCardObj[id]) {
      this.priorityCardObj[id] = false;
    }
  };

  userHasSeenAllPriorityCards = () => {
    return Object.keys(this.priorityCardObj).every(
      (cardId) => this.priorityCardObj[cardId]
    );
  };

  clearQueue = () => {
    this.unload = true;
  };
}
