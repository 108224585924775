import React, { useContext } from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";

import favicon from "src/images/favicon.ico";
import Settings from "src/stores/Settings";
import mergeSansNullOrUndefined from "src/utils/mergeSansNullOrUndefined";

const SEO = ({ path, meta, jsonld = [], hreflang, localizedSlugs }) => {
  const { seo, locale, socialLinks, headerLogo } = useContext(Settings);

  if (locale.currentRegion.region === "CA") {
    const title = seo.title.split("|")[1].trim();
    jsonld.push({
      "@context": "https://schema.org",
      "@type": "Organization",
      name: title,
      url: "https://www.activia.ca/en/",
      logo: headerLogo.src,
      sameAs: socialLinks.map((l) => l.url),
    });
  }

  const {
    title,
    description,
    image,
    twitterAuthor,
    facebookPageId,
    siteName,
    canonicalUrLs,
  } = mergeSansNullOrUndefined(seo, meta);

  const url = `${locale.currentRegion.url}${path}`;
  const imgSrc = image && image.src;
  const imgUrl = `https:${imgSrc}`;
  const imgUrlArr = imgUrl && imgUrl.split(".");
  const imgType = imgUrl && `image/${imgUrlArr[imgUrlArr.length - 1]}`;

  const pageHreflang =
    (hreflang &&
      hreflang.url
        .filter((curr) => curr[locale.currentRegion.region] === path)
        .reduce((acc, curr) => {
          return Object.keys(curr).map((key) => {
            return {
              locale: hreflang.data[key].locale,
              url: `${hreflang.data[key].domain}${curr[key]}`,
            };
          });
        }, [])) ||
    [];

  const localeInclusiveHrefLang =
    (localizedSlugs &&
      localizedSlugs.length &&
      localizedSlugs.map((s) => {
        return {
          locale: s.locale.toLowerCase(),
          url: `${locale.currentRegion.url}${s.pathPrefix}${s.slug}`,
        };
      })) ||
    [];

  if (localeInclusiveHrefLang.length) {
    // Add the current url to the locale based hreflang
    localeInclusiveHrefLang.push({
      locale: locale.currentLocaleCode.toLowerCase(),
      url: url,
    });
  }

  const allHrefLang = [...pageHreflang, ...localeInclusiveHrefLang];

  const canonical = canonicalUrLs || [];

  return (
    <Helmet
      htmlAttributes={{
        lang: locale.currentLocaleCode,
      }}
      title={title}
      link={[
        { rel: "shortcut icon", type: "image/ico", href: `${favicon}` },
        ...canonical.map((u) => ({
          rel: "canonical",
          href: u,
        })),
        {
          rel: "preconnect",
          href: "https://s.ytimg.com",
          crossorigin: true,
        },
        {
          rel: "preconnect",
          href: "https://adservice.google.com",
          crossorigin: true,
        },
        {
          rel: "preconnect",
          href: "https://youtube.com",
          crossorigin: true,
        },
        {
          rel: "preconnect",
          href: "https://www.youtube.com",
          crossorigin: true,
        },
        {
          rel: "preconnect",
          href: "https://www.google-analytics.com",
          crossorigin: true,
        },
        {
          rel: "preconnect",
          href: "https://engage.commander1.com",
          crossorigin: true,
        },
        ...allHrefLang.map((h) => ({
          rel: "alternate",
          href: h.url,
          hreflang: h.locale,
        })),
      ]}
      meta={[
        // static
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `theme-color`,
          content: `#FFFFFF`,
        },
        // locale
        {
          property: `og:locale`,
          content: locale.currentLocaleCode.replace("-", "_"),
        },
        // url
        {
          property: `og:url`,
          content: url,
        },
        {
          name: `twitter:url`,
          content: url,
        },

        // description
        {
          property: `og:title`,
          content: title,
        },
        {
          name: `twitter:title`,
          content: title,
        },

        // description
        {
          name: `description`,
          content: description,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          name: `twitter:description`,
          content: description,
        },

        // image
        // og:image, width, height, type
        // twitter:image
        ...(image
          ? [
              {
                property: `og:image`,
                content: imgUrl,
              },
              {
                property: `og:image:width`,
                content: image.width,
              },
              {
                property: `og:image:height`,
                content: image.height,
              },
              {
                property: `og:image:type`,
                content: imgType,
              },
              {
                name: `twitter:image`,
                content: imgUrl,
              },
            ]
          : []),

        // site name
        {
          property: `og:site_name`,
          content: siteName,
        },

        // external IDs
        {
          name: `fb:page_id`,
          content: facebookPageId,
        },
        {
          name: `twitter:creator`,
          content: twitterAuthor,
        },
        {
          name: `twitter:site`,
          content: twitterAuthor,
        },
      ]}
    >
      {!!jsonld && (
        <script type="application/ld+json">{JSON.stringify(jsonld)}</script>
      )}
    </Helmet>
  );
};

SEO.propTypes = {
  meta: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.shape({
      src: PropTypes.string.isRequired,
      width: PropTypes.number.isRequired,
      height: PropTypes.number.isRequired,
    }),
    twitterAuthor: PropTypes.string,
    facebookPageId: PropTypes.string,
    siteName: PropTypes.string,
  }),
  path: PropTypes.string.isRequired,
  jsonld: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  hreflang: PropTypes.object,
  localizedSlugs: PropTypes.array,
};

export default SEO;
