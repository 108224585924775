// given a list of objects, like
// mergeSansNull({ foo: 'foo'}, { bar: 'bar' }, { qux: null })
// will merge the objects together without nulls or undefined values

import filterNullOrUndefined from "./filterNullOrUndefined";

export default (...objs) => {
  return objs.reduce(
    (acc, obj) => ({
      ...acc,
      ...filterNullOrUndefined(obj),
    }),
    {}
  );
};
