import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import FilterDrawer from "src/molecules/FilterDrawer";
import TheGutLifeGrid, {
  GridContextProvider,
  COOKIE,
} from "src/molecules/TheGutLifeGrid";
import Cookie from "js-cookie";
import { TYPES } from "src/molecules/Card";
import Settings from "src/stores/Settings";
//import trackEvent from "./trackEvent";

const TheGutLifeGridWithFilters = ({
  CardComponent,
  hint,
  title,
  cardData,
  priorityCardData,
}) => {
  const { translations } = useContext(Settings);
  const [filters, setFilters] = useState({});
  const [instructionsSeen, setInstructionsSeen] = useState(
    !!Cookie.get(COOKIE.instructions.name)
  );
  const contentTypeMap = Object.keys(TYPES).reduce(
    (acc, key) => ({
      ...acc,
      [TYPES[key]]: translations[TYPES[key]],
    }),
    {}
  );

  const categories = cardData
    .reduce((acc, card) => {
      if (
        card.category &&
        card.category.name &&
        acc.indexOf(card.category.name) < 0
      ) {
        acc.push(card.category.name);
      }
      return acc;
    }, [])
    .reduce((acc, c) => [...acc, { key: c, label: c }], []);

  const contentTypes = cardData
    .reduce((acc, card) => {
      if (card.__typename && acc.indexOf(card.__typename) < 0) {
        acc.push(card.__typename);
      }
      return acc;
    }, [])
    .reduce((acc, key) => {
      if (key === TYPES.editorialIllustration) {
        return acc;
      }
      return [...acc, { key, label: contentTypeMap[key] }];
    }, []);

  const filterGrid = (appliedFilters) => {
    //trackEvent(appliedFilters, contentTypeMap);
    setFilters(appliedFilters);
  };

  return (
    <GridContextProvider
      CardComponent={CardComponent}
      cardData={cardData}
      title={title}
      hint={hint}
      priorityCardData={priorityCardData}
      filters={filters}
      instructionsSeen={instructionsSeen}
      setInstructionsSeen={setInstructionsSeen}
    >
      <GutLifeWrapper>
        <TheGutLifeGrid />
        {instructionsSeen && (
          <FilterDrawer
            categories={categories}
            contentTypes={contentTypes}
            onFilterChange={filterGrid}
          />
        )}
      </GutLifeWrapper>
    </GridContextProvider>
  );
};

const GutLifeWrapper = styled.div`
  width: 100vw;
  height: 100vh;
`;

TheGutLifeGridWithFilters.propTypes = {
  CardComponent: PropTypes.elementType,
  hint: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  cardData: PropTypes.array.isRequired,
  priorityCardData: PropTypes.array.isRequired,
};

export default TheGutLifeGridWithFilters;
