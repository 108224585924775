import React, { useEffect, useState, useContext } from "react";
import debounce from "src/utils/debounce";
import DesktopGrid from "./DesktopGrid";
import MobileGrid from "./MobileGrid";
import getViewport from "src/utils/viewport";
import { Visible } from "react-awesome-styled-grid";
import { DIMENSIONS } from "./constants";
import PageContext from "src/stores/Page";
import Settings from "src/stores/Settings";
import { GridContext } from "./GridContext";
import TheGutLifeInstructions from "src/molecules/TheGutLifeInstructions";

const GutGrid = () => {
  const [gridData, setGridData] = useState({});
  const {
    visibleHeader: [, setVisibleHeader],
  } = useContext(PageContext);

  const { instructionsSeen, filters } = useContext(GridContext);
  const { emptyGridHint, emptyGridTitle } = useContext(Settings).translations;

  const buildGridData = () => {
    const viewport = getViewport();
    const data = {
      viewRows: Math.ceil(viewport.height / DIMENSIONS.height + 2),
      viewColumns: Math.ceil(viewport.width / DIMENSIONS.width + 2),
      viewWidth: viewport.width,
      viewHeight: viewport.height,
    };

    data.centerCell = {
      col: Math.floor(data.viewColumns / 2),
      row: Math.floor(data.viewRows / 2),
    };

    setGridData(data);
  };

  useEffect(() => {
    buildGridData();
    const debouncedOnResize = debounce(buildGridData, 250);
    window.addEventListener("resize", debouncedOnResize);
    return () => {
      window.removeEventListener("resize", debouncedOnResize);
    };
  }, []);

  return (
    (gridData.viewRows && (
      <>
        {!instructionsSeen && <TheGutLifeInstructions />}
        <Visible md lg xl>
          <DesktopGrid
            gridData={gridData}
            filters={filters}
            emptyGridHint={emptyGridHint}
            emptyGridTitle={emptyGridTitle}
          />
        </Visible>
        <Visible xs sm>
          <MobileGrid
            gridData={gridData}
            setVisibleHeader={setVisibleHeader}
            filters={filters}
            emptyGridHint={emptyGridHint}
            emptyGridTitle={emptyGridTitle}
          />
        </Visible>
      </>
    )) ||
    null
  );
};

export default GutGrid;
