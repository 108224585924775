import React, { useState, useEffect, useContext } from "react";
import styled, { css, keyframes } from "styled-components";
import PropTypes from "prop-types";
import WelcomeBanner from "src/molecules/TheGutLifeWelcome";
import { GridContext } from "./GridContext";
import { DIMENSIONS, TYPES } from "src/molecules/Card";

/**
 * Handles the animation of the card when it appears in the viewport
 */
const GridCard = ({
  cardData,
  x,
  y,
  mobile = false,
  loadAnimated = true,
  empty = false,
  ...rest
}) => {
  const [isHidden, setIsHidden] = useState(true);
  const { CardComponent, hint, title, isMoving } = useContext(GridContext);

  useEffect(() => {
    setIsHidden(false);
  }, []);

  useEffect(() => {
    setIsHidden(true);
    setIsHidden(false);
  }, [cardData]);

  const props = !empty
    ? {
        ...cardData,
        cardHighlighted: !mobile && cardData.cardHighlighted,
        loadAnimated,
      }
    : { __typename: TYPES.empty, loadAnimated };

  return (
    <Wrapper
      isHidden={isHidden}
      x={x}
      y={y}
      cardHighlighted={cardData && cardData.cardHighlighted}
      mobile={mobile}
      isMoving={isMoving}
      {...rest}
    >
      {cardData && cardData.id === "center" ? (
        <WelcomeWrapper>
          <WelcomeBanner hint={hint} title={title} showCta={false} />
        </WelcomeWrapper>
      ) : (
        <CardComponent {...props} />
      )}
    </Wrapper>
  );
};

// This will change from div to Card
const Wrapper = styled.div.attrs(
  ({ isHidden, x, y, cardHighlighted = false, mobile = false, isMoving }) => ({
    style: {
      transform: !mobile && isHidden ? null : `translate3d(${x}px, ${y}px, 0)`,
      opacity: isHidden ? 0 : null,
      width:
        !mobile && cardHighlighted
          ? DIMENSIONS.width * 2 + DIMENSIONS.gutter
          : DIMENSIONS.width,
      pointerEvents: isMoving ? "none" : null,
    },
  })
)`
  position: ${({ mobile }) => (mobile ? "relative" : "absolute")};
  height: ${DIMENSIONS.height}px;
  margin-right: ${DIMENSIONS.gutter}px;
  margin-bottom: ${DIMENSIONS.gutter}px;
  user-select: none;
  will-change: transform, opacity;
`;

const showTransition = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const showAnimation = css`
  animation: ${showTransition} 1s cubic-bezier(0.47, 0, 0.37, 1) forwards;
`;

const WelcomeWrapper = styled.div`
  ${showAnimation}
`;

GridCard.propTypes = {
  cardData: PropTypes.object,
  x: PropTypes.number,
  y: PropTypes.number,
  mobile: PropTypes.bool,
  loadAnimated: PropTypes.bool,
  empty: PropTypes.bool,
};

export default GridCard;
