import React, { Component } from "react";
import styled, { keyframes } from "styled-components";
import PropTypes from "prop-types";
import DraggableWrapper from "./DraggableWrapper";
import GridCard from "./GridCard";
import CardQueue from "./CardQueue";
import CardCache from "./CardCache";
import { GridContext } from "./GridContext";
import Cookies from "js-cookie";
import filterCards from "src/utils/cardFilter";
import TheGutLifePreview from "src/organisms/TheGutLifePreview";
import FilteredDesktop from "./FilteredDesktop";

import {
  DIMENSIONS,
  CELL_NEIGHBOURS,
  DESKTOP_CENTER_NEIGHBOURS,
  COOKIE,
} from "./constants";

class DesktopGrid extends Component {
  constructor() {
    super();

    this.cache = new CardCache();
    this.state = {
      visibleCells: {},
      offsetX: 0,
      offsetY: 0,
      filteredCards: [],
    };
  }

  componentDidMount() {
    const { instructionsSeen, setInstructionsSeen } = this.context;
    if (!instructionsSeen) {
      Cookies.set(COOKIE.instructions.name, COOKIE.instructions.value, {
        secure: true,
        HTTPOnly: false,
      });

      setTimeout(() => {
        setInstructionsSeen(true);
        this.initialLoad();
      }, 3000);
    } else {
      this.initialLoad();
    }

    if (
      this.props.filters &&
      (this.props.filters.category || this.props.filters.contentType)
    ) {
      this.applyFilters(this.props.filters);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.gridData.viewWidth !== this.props.gridData.viewWidth ||
      prevProps.gridData.viewHeight !== this.props.gridData.viewHeight
    ) {
      if (this.props.filters) {
        this.applyFilters(this.props.filters);
      } else {
        this.updateGrid();
      }
    } else if (prevProps.filters !== this.props.filters) {
      this.applyFilters(this.props.filters);
    }
  }

  initialLoad = () => {
    const { cardData, priorityCardData } = this.context;

    this.cardQueue = new CardQueue(cardData, priorityCardData);
    this.buildInitialGridCache();
    const { offsetX, offsetY } = this.getInitialOffset();
    this.setState({ offsetX, offsetY }, () => {
      this.updateGrid(true);
    });
  };

  applyFilters = (filters) => {
    const { cardData } = this.context;
    this.cache.clearCache();
    this.setState(
      { visibleCells: {}, offsetX: 0, offsetY: 0, filteredCards: [] },
      () => {
        if (
          !filters.category ||
          !filters.contentType ||
          (!filters.category.length && !filters.contentType.length)
        ) {
          this.initialLoad();
        } else {
          const filteredCardData = filterCards(filters, cardData);
          if (filteredCardData.length) {
            this.setState({ filteredCards: filteredCardData });
          }
        }
      }
    );
  };

  getInitialOffset = () => {
    const { centerCell, viewWidth, viewHeight } = this.props.gridData;
    const { col, row } = centerCell;
    const cardHorizontalOffset = DIMENSIONS.doubleWidth / 2;
    const cardVerticalOffset = DIMENSIONS.height / 2;
    const horizontalCenter = viewWidth / 2 - cardHorizontalOffset;
    const verticalCenter = viewHeight / 2 - cardVerticalOffset;
    const [x, y] = this.getCardPosition(col, row, true);
    return {
      offsetX: horizontalCenter - x + 10,
      offsetY: verticalCenter - y + 20,
    };
  };

  buildInitialGridCache = () => {
    const { centerCell } = this.props.gridData;
    const { col, row } = centerCell;
    const cell = `${col}:${row}`;
    const placeholderCell = `${col + 1}:${row}`;
    this.cache.cacheCell(cell, "center");
    this.cache.cacheCell(placeholderCell, "placeholder");

    // Center surrounding cards
    DESKTOP_CENTER_NEIGHBOURS.forEach((offset, idx) => {
      const cell = `${col + offset[0]}:${row + offset[1]}`;

      // As long as we haven't put anything in this cached cell....
      if (!this.cache.getIdFromCachedCell(cell)) {
        const id = this.cardQueue.getNextCardId();
        const cardIsDouble = this.isCardDouble(id);

        // Edge case for last card (left of the center)
        if (idx === DESKTOP_CENTER_NEIGHBOURS.length - 1 && cardIsDouble) {
          const leftCell = `${col + offset[0] - 1}:${row + offset[1]}`;
          this.cache.cacheCell(leftCell, id);
          this.cache.cacheCell(cell, "placeholder");
          return true;
        }

        if (cardIsDouble) {
          const rightCell = `${col + offset[0] + 1}:${row + offset[1]}`;
          this.cache.cacheCell(rightCell, "placeholder");
          this.cache.cacheCell(cell, id);
          return true;
        }

        this.cache.cacheCell(cell, id);
        return true;
      }
    });
  };

  isCardDouble = (cardId) => {
    const cardData = cardId && this.cardQueue.getCardById(cardId);
    return cardData && cardData.cardHighlighted;
  };

  isCardValid = (id, col, row) => {
    // Check card is not the same than any of the surrounding
    for (let i = 0; i < CELL_NEIGHBOURS.length; i++) {
      const offsets = CELL_NEIGHBOURS[i];
      const cell = `${col + offsets[0]}:${row + offsets[1]}`;
      if (this.cache.getIdFromCachedCell(cell) === id) {
        return false;
      }
    }

    // Check that the card will fit in the spot (double with a card on the right/left)
    if (this.isCardDouble(id)) {
      const rightNeighbor = `${col + 1}:${row}`;
      const leftNeighbor = `${col - 1}:${row}`;
      const rightId = this.cache.getIdFromCachedCell(rightNeighbor);
      const leftId = this.cache.getIdFromCachedCell(leftNeighbor);

      // won't fit
      if (leftId && rightId) {
        return false;
      }

      // Fits, but wide neighbor is the same
      if ((rightId && rightId === id) || (leftId && leftId === "placeholder")) {
        return false;
      }
    }
    return true;
  };

  getValidNeighborId = (col, row) => {
    while (true) {
      const id = this.cardQueue.getNextCardId();
      const isCardSafe = this.isCardValid(id, col, row);

      if (isCardSafe) {
        return id;
      }
    }
  };

  getCardIdByGridPosition = (col, row) => {
    let cell = `${col}:${row}`;
    const cachedCell = this.cache.getIdFromCachedCell(cell);
    if (cachedCell) {
      return cachedCell;
    }

    const cardId = this.getValidNeighborId(col, row);
    if (this.isCardDouble(cardId)) {
      const rightNeighbor = `${col + 1}:${row}`;
      if (this.cache.getIdFromCachedCell(rightNeighbor)) {
        //shift to the left if there is a card to the right and I'm double
        // At this point it's confirmed that there is no card on the left
        this.cache.cacheCell(cell, "placeholder");
        cell = `${col - 1}:${row}`;
        this.cache.cacheCell(cell, cardId);
        return "placeholder";
      } else {
        // fill cell and placeholder normally as there is nothing on the right
        this.cache.cacheCell(rightNeighbor, "placeholder");
      }
    }
    this.cache.cacheCell(cell, cardId);
    return cardId;
  };

  isCardVisible = (x, y, isDouble = false) => {
    const { gridData } = this.props;
    const width = isDouble ? DIMENSIONS.doubleWidth : DIMENSIONS.width;
    return (
      x + width > 0 &&
      y + DIMENSIONS.height > 0 &&
      x < gridData.viewWidth &&
      y < gridData.viewHeight
    );
  };

  getCardPosition = (col, row) => {
    const { offsetX, offsetY } = this.state;
    const cardOffsetX = offsetX % DIMENSIONS.width;
    const cardOffsetY = offsetY % DIMENSIONS.height;
    const x = col * DIMENSIONS.width + cardOffsetX - DIMENSIONS.width;
    const y = row * DIMENSIONS.height + cardOffsetY - DIMENSIONS.height;
    return [Math.round(x), Math.round(y)];
  };

  updateGrid = (initialLoad = false) => {
    const { offsetX, offsetY } = this.state;
    const { viewRows, viewColumns } = this.props.gridData;
    const newCards = {};
    // Using ~~ instead of Math.floor as it is faster
    // See: https://stackoverflow.com/questions/5971645/what-is-the-double-tilde-operator-in-javascript
    const colOffset = ~~(offsetX / DIMENSIONS.width) * -1;
    const rowOffset = ~~(offsetY / DIMENSIONS.height) * -1;

    const initialCell = initialLoad ? 0 : -1;

    for (let col = initialCell; col < viewColumns; col++) {
      for (let row = initialCell; row < viewRows; row++) {
        const newCol = colOffset + col;
        const newRow = rowOffset + row;
        const cell = `${newCol}:${newRow}`;
        const [x, y] = this.getCardPosition(col, row);
        const cardId = this.getCardIdByGridPosition(newCol, newRow);

        if (this.isCardVisible(x, y, this.isCardDouble(cardId))) {
          newCards[cell] = { id: cardId, x, y };
        }
      }
    }

    this.setState({ visibleCells: newCards });
  };

  onDragHandler = (deltaX, deltaY) => {
    const { offsetX, offsetY } = this.state;
    const newOffsetX = offsetX + deltaX;
    const newOffsetY = offsetY + deltaY;
    this.setState(
      {
        offsetX: newOffsetX,
        offsetY: newOffsetY,
      },
      () => {
        this.updateGrid();
      }
    );
  };

  render() {
    const { visibleCells, filteredCards } = this.state;
    const { instructionsSeen } = this.context;
    return Object.keys(visibleCells).length ? (
      <DraggableWrapper onDrag={this.onDragHandler}>
        {Object.keys(visibleCells).map((cell) => {
          const { id, x, y } = visibleCells[cell];

          return (
            id &&
            id !== "placeholder" && (
              <GridCard
                cardData={this.cardQueue.getCardById(id)}
                x={x}
                y={y}
                key={cell}
              />
            )
          );
        })}
      </DraggableWrapper>
    ) : filteredCards.length ? (
      <FilteredDesktop cards={filteredCards} gridData={this.props.gridData} />
    ) : instructionsSeen ? (
      <NrWrapper>
        <NoResults
          welcome={{
            hint: this.props.emptyGridHint,
            title: this.props.emptyGridTitle,
            showCta: false,
          }}
          fill={false}
        />
      </NrWrapper>
    ) : null;
  }
}

const scaleUp = keyframes`
  0% {
    transform: scale(0), translateY(-50%);
    opacity: .2;
  }

  100% {
    transform: scale(1), translateY(-50%);
    opacity: 1;
  }
`;

const NrWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
`;

const NoResults = styled(TheGutLifePreview)`
  margin: auto;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  width: 100%;
  animation: ${scaleUp} 0.5s cubic-bezier(0.47, 0, 0.37, 1);
  transform-origin: 50% 50%;
`;

DesktopGrid.contextType = GridContext;

DesktopGrid.propTypes = {
  gridData: PropTypes.object.isRequired,
  filters: PropTypes.object,
  emptyGridHint: PropTypes.string.isRequired,
  emptyGridTitle: PropTypes.string.isRequired,
};

export default DesktopGrid;
