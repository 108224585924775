import React, { useState } from "react";
import loadable from "@loadable/component";
import styled, { css, keyframes } from "styled-components";
/* TODO: investigate why importing from index breaks the build */
import { DIMENSIONS } from "src/molecules/TheGutLifeGrid/constants";
const TheGutLifeInstructionsLottie = loadable(() =>
  import("src/atoms/Lottie/TheGutGridInstructionsLottie")
);

export default () => {
  const [finished, setFinished] = useState(false);
  setTimeout(() => {
    setFinished(true);
  }, 3500);
  return (
    !finished && (
      <InstructionsOverlay>
        <AnimationWrapper>
          <TheGutLifeInstructionsLottie />
        </AnimationWrapper>
      </InstructionsOverlay>
    )
  );
};

const fadeKeyframe = keyframes`
  0% {
    opacity: 0;
  }
  15% {
    opacity: 1;
  }
  90% {
    opacity: 1;
    pointer-events: all;
  }
  100% {
    opacity: 0;
    pointer-events: none;
  }
`;

const fadeAnimation = css`
  animation: ${fadeKeyframe} 3s cubic-bezier(0.47, 0, 0.37, 1) forwards;
`;

const AnimationWrapper = styled.div`
  width: ${DIMENSIONS.doubleWidth}px;
  height: ${DIMENSIONS.height}px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  overflow: hidden;

  svg {
    margin-top: -70px;
  }
`;

const InstructionsOverlay = styled.div`
  background: rgba(255, 255, 255, 0.95);
  width: 100vw;
  height: 100vh;
  position: fixed;
  opacity: 1;
  ${fadeAnimation}
`;
