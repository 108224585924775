import { TYPES } from "src/molecules/Card";

const filterCards = (filters, cards) => {
  const filteredByCategory = filters.category.length
    ? cards.filter(
        (card) => card.category && filters.category.includes(card.category.name)
      )
    : cards;

  const contenTypeFilters = filters.contentType && filters.contentType.slice(0);

  if (
    contenTypeFilters &&
    contenTypeFilters.includes(TYPES.editorialPhotography)
  ) {
    contenTypeFilters.push(TYPES.editorialIllustration);
  }
  return contenTypeFilters.length
    ? filteredByCategory.filter(
        (card) => card.__typename && contenTypeFilters.includes(card.__typename)
      )
    : filteredByCategory;
};

export default filterCards;
