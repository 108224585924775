// Generates breadcrumb object for json ld

const breadcrumb = (params) => ({
  "@context": "https://schema.org",
  "@type": "BreadcrumbList",
  itemListElement: params.map(({ name, url }, index) => ({
    "@type": "ListItem",
    position: index + 1,
    name,
    item: url,
  })),
});

export default breadcrumb;
