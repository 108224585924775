import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import GridCard from "./GridCard";
import { DIMENSIONS } from "src/molecules/Card";
import { colors } from "src/styles/variables";

const FilteredMobile = ({ cards }) => {
  return (
    <>
      <FirstRow>
        <FilterCard empty />
        <FilterCard empty />
        <FilterCard empty />
      </FirstRow>
      {cards.map((card) => (
        <Row key={card.id}>
          <FilterCard empty />
          <FilterCard cardData={card} mobile />
          <FilterCard empty />
        </Row>
      ))}
      <LastRow>
        <FilterCard empty />
        <FilterCard empty />
        <FilterCard empty />
      </LastRow>
    </>
  );
};

const gradient = (side) => `
  content: "";
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  pointer-events: none;
  height: ${DIMENSIONS.height + DIMENSIONS.gutter}px;
  ${side}: 0;
  background-image: linear-gradient(
    to ${side},
    ${colors.cardFadeGradient[1]} 0%,
    ${colors.cardFadeGradient[0]} 45%
  );
`;

const Row = styled.div`
  height: ${DIMENSIONS.height + DIMENSIONS.gutter}px;
  width: ${(DIMENSIONS.width + DIMENSIONS.gutter) * 3}px;
  transform: translateX(${DIMENSIONS.gutter}px);
  display: flex;
  &:first-child:before {
    ${gradient("top")}
  }
  &:last-child:after {
    ${gradient("bottom")}
  }
`;

const FirstRow = styled(Row)`
  margin-top: -${DIMENSIONS.height / 2}px;
`;

const LastRow = styled(Row)`
  margin-bottom: -${DIMENSIONS.height / 2}px;
`;

const FilterCard = styled(GridCard)`
  position: relative;
`;

FilteredMobile.propTypes = {
  cards: PropTypes.arrayOf(PropTypes.object),
};

export default FilteredMobile;
