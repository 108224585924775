// given an object, like
// filterNullOrUndefined({ foo: 'foo', bar: null })
// will return the object without nulls or undefined values
//
export default (obj) =>
  Object.entries(obj).reduce(
    (acc, [key, val]) => ({
      ...acc,
      ...(typeof val === "undefined" || val === null ? {} : { [key]: val }),
    }),
    {}
  );
