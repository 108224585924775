import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { SettingsStore, SettingsShape } from "src/stores/Settings";
import { PageStore } from "src/stores/Page";
import Layout from "src/layouts/Main";
import SEO from "src/atoms/SEO";
import Card, {
  TYPES,
  CardShape,
  ProductCardShape,
  FactCardShape,
} from "src/molecules/Card";
import TheGutLifeGridWithFilters from "src/organisms/TheGutLifeGridWithFilters";
// import ScreenReaderTitle from "src/atoms/ScreenReaderTitle";
import getMetaImageFields from "src/utils/getMetaImageFields";
import { TYPES as PICTURE_TYPES } from "src/atoms/Picture";
import breadcrumb from "src/utils/breadcrumb";
import trackEvent from "./trackEvent";

const TheGutLifePage = ({
  data,
  pageContext: { locale, localizedSlugs },
  path,
}) => {
  if (!data) {
    throw new Error(`Page query failed: reason unknown`);
  }

  const settings = {
    ...data.contentfulSettings,
    locale,
    localizedSlugs,
  };
  const theGutLifeGrid = data.contentfulTheGutLifeGrid;
  const { priorityCards, shouldHaveProductCards } = theGutLifeGrid;
  const includeProducts =
    shouldHaveProductCards === null ? true : shouldHaveProductCards;

  const {
    allContentfulEditorialIllustrationWithCard,
    allContentfulEditorialPhotographyWithCard,
    allContentfulProductFlavorWithCard,
    allContentfulInfluencerWithCard,
    allContentfulRecipeWithCard,
    allContentfulFactJustCard,
  } = data;

  const collections = [
    {
      data: allContentfulInfluencerWithCard.nodes,
      __typename: TYPES.influencer,
    },
    {
      data: allContentfulEditorialPhotographyWithCard.nodes,
      __typename: TYPES.editorialPhotography,
    },
    {
      data: allContentfulEditorialIllustrationWithCard.nodes,
      __typename: TYPES.editorialIllustration,
    },
    { data: allContentfulRecipeWithCard.nodes, __typename: TYPES.recipe },
    { data: allContentfulFactJustCard.nodes, __typename: TYPES.fact },
  ];

  if (includeProducts) {
    collections.push({
      data: allContentfulProductFlavorWithCard.nodes.filter((product) => {
        // Check for locale exclusive categories (multi lang but it's exclusive to one locale)
        // Current exceptions: categories - /shot, /smoothie, /rostlinna, exlusive to - cs-CZ, other locales: sk
        const exceptions = ["/shot", "/smoothie", "/rostlinna"];
        const category = product.subcategory[0].category[0];
        if (
          locale.currentLocaleCode === "sk" &&
          exceptions.includes(category.slug)
        ) {
          return false;
        }

        return true;
      }),
      __typename: TYPES.product,
    });
  }

  const cardData = collections
    .flatMap((collection) =>
      collection.data.map((c) => ({
        ...c,
        __typename: collection.__typename,
      }))
    )
    .sort(() => Math.random() - 0.5);

  const priorityCardData = priorityCards.filter((c) => {
    if (!includeProducts && c.__typename === TYPES.product) {
      return false;
    }
    return true;
  });

  const { title, hint, seo } = theGutLifeGrid;

  const pageMetadata = {
    ...seo,
    image: seo.image && getMetaImageFields(seo.image, PICTURE_TYPES.fixed),
  };

  const fullUrl = `${locale.currentRegion.url}${path}`;
  const jsonld = [breadcrumb([{ name: seo && seo.title, url: fullUrl }])];

  useEffect(() => {
    window.tc_vars = {
      country: locale.currentRegion.region,
      environment: process.env.GATSBY_TRACKING_ENV,
      page_category: "The Gut Life Grid",
      page_name: seo.title,
      page_type: "The Gut Life Page",
      page_subcategory: "",
      page: path,
      work_env: process.env.GATSBY_TRACKING_ENV,
      brand: "Activia",
    };
    window.tC.container.reload({ events: { page_view: [{}, {}] } });

    trackEvent();
    //console.log('window.tc_vars the gut life',window.tc_vars);
  }, []);

  return (
    <SettingsStore value={settings}>
      <SEO
        path={path}
        meta={pageMetadata}
        jsonld={jsonld}
        localizedSlugs={localizedSlugs}
      />
      <PageStore initOverride={{ transparentHeader: false }}>
        <Layout footer={false} hideHeaderOnScroll={false} locale={locale}>
          <TheGutLifeGridWithFilters
            CardComponent={Card}
            cardData={cardData}
            title={title}
            hint={hint}
            priorityCardData={priorityCardData}
          />
        </Layout>
      </PageStore>
    </SettingsStore>
  );
};

TheGutLifePage.propTypes = {
  data: PropTypes.shape({
    contentfulSettings: PropTypes.shape(SettingsShape).isRequired,
    contentfulTheGutLifeGrid: PropTypes.shape({
      seo: PropTypes.object.isRequired,
      hint: PropTypes.string,
      title: PropTypes.string,
      priorityCards: PropTypes.arrayOf(
        PropTypes.oneOfType([CardShape, ProductCardShape])
      ),
      shouldHaveProductCards: PropTypes.bool,
    }),
    allContentfulEditorialPhotographyWithCard: CardShape,
    allContentfulEditorialIllustrationWithCard: CardShape,
    allContentfulInfluencerWithCard: CardShape,
    allContentfulRecipeWithCard: CardShape,
    allContentfulProductFlavorWithCard: ProductCardShape,
    allContentfulFactJustCard: PropTypes.shape({
      nodes: PropTypes.arrayOf(FactCardShape),
    }),
  }),
  pageContext: PropTypes.shape({
    locale: PropTypes.object.isRequired,
    localizedSlugs: PropTypes.instanceOf(Array),
  }).isRequired,
  path: PropTypes.string.isRequired,
};

export const pageQuery = graphql`
  query getGutLifeGridPage($nodeLocale: String!, $id: String!, $tag: String!) {
    contentfulSettings(
      metadata: { tags: { elemMatch: { contentful_id: { eq: $tag } } } }
      node_locale: { eq: $nodeLocale }
    ) {
      ...SettingsFields
    }

    contentfulTheGutLifeGrid(
      metadata: { tags: { elemMatch: { contentful_id: { eq: $tag } } } }
      node_locale: { eq: $nodeLocale }
      contentful_id: { eq: $id }
    ) {
      ...TheGutLifeGrid
    }

    allContentfulEditorialPhotographyWithCard(
      limit: 100
      filter: {
        metadata: { tags: { elemMatch: { contentful_id: { eq: $tag } } } }
        node_locale: { eq: $nodeLocale }
      }
    ) {
      nodes {
        ...EditorialPhotographyCard
      }
    }

    allContentfulEditorialIllustrationWithCard(
      limit: 100
      filter: {
        metadata: { tags: { elemMatch: { contentful_id: { eq: $tag } } } }
        node_locale: { eq: $nodeLocale }
      }
    ) {
      nodes {
        ...EditorialIllustrationCard
      }
    }

    allContentfulInfluencerWithCard(
      limit: 100
      filter: {
        metadata: { tags: { elemMatch: { contentful_id: { eq: $tag } } } }
        node_locale: { eq: $nodeLocale }
      }
    ) {
      nodes {
        ...InfluencerCard
      }
    }

    allContentfulRecipeWithCard(
      limit: 100
      filter: {
        metadata: { tags: { elemMatch: { contentful_id: { eq: $tag } } } }
        node_locale: { eq: $nodeLocale }
      }
    ) {
      nodes {
        ...RecipeCard
      }
    }

    allContentfulProductFlavorWithCard(
      limit: 100
      filter: {
        metadata: { tags: { elemMatch: { contentful_id: { eq: $tag } } } }
        node_locale: { eq: $nodeLocale }
      }
    ) {
      nodes {
        ...FlavorCard
      }
    }

    allContentfulFactJustCard(
      limit: 100
      filter: {
        metadata: { tags: { elemMatch: { contentful_id: { eq: $tag } } } }
        node_locale: { eq: $nodeLocale }
      }
    ) {
      nodes {
        ...FactCard
      }
    }
  }
`;

export default TheGutLifePage;
