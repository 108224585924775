export default class CardQueue {
  constructor(cardData, priorityCardList) {
    const prioritisedCardObj = priorityCardList.reduce(
      (cardMap, currentCard) => ({
        ...cardMap,
        [currentCard.id]: currentCard,
      }),
      {}
    );

    const priorityCardIds = priorityCardList
      .map((card) => card.id)
      .reduce((list, id) => {
        if (!list.includes(id)) {
          list.push(id);
        }
        return list;
      }, []);

    const dedupedPriorityCardList = priorityCardIds.map(
      (id) => prioritisedCardObj[id]
    );

    const unprioritisedCardList = cardData
      .sort(() => Math.random() - 0.5)
      .filter((card) => {
        return !prioritisedCardObj[card.id];
      })
      .map((card) => ({ ...card, cardHighlighted: false }));

    this.cardDataArray = dedupedPriorityCardList.concat(unprioritisedCardList);
    this.cardDataObj = this.cardDataArray.reduce(
      (cardMap, currentCard) => ({
        ...cardMap,
        [currentCard.id]: currentCard,
      }),
      {}
    );
    this.currentIndex = 0;
  }

  getCardById = (id) => {
    if (id === "center") {
      return {
        id: "center",
        cardHighlighted: true,
      };
    }
    return id && this.cardDataObj[id];
  };

  getNextCardId = () => {
    const nextCard = this.cardDataArray[this.currentIndex];
    this.currentIndex++;
    if (this.currentIndex === this.cardDataArray.length) {
      this.currentIndex = 0;
      this.cardDataArray = this.cardDataArray.sort(() => Math.random() - 0.5);
    }
    return nextCard.id;
  };
}
