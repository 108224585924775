import React, { Component, createRef } from "react";
import styled, { keyframes } from "styled-components";
import PropTypes from "prop-types";
import { GridContext } from "./GridContext";
import GridCarousel from "./GridCarousel";
import MobileCardQueue from "./MobileCardQueue";
import { DIMENSIONS } from "src/molecules/Card";
import { MOBILE_ROWS, COOKIE } from "./constants";
import throttle from "src/utils/throttle";
import filterCards from "src/utils/cardFilter";
import TheGutLifePreview from "src/organisms/TheGutLifePreview";
import FilteredMobile from "./FilteredMobile";
import Swipeable from "src/molecules/SwipeableClass";
import Cookies from "js-cookie";

class MobileGrid extends Component {
  constructor() {
    super();
    this.wrapper = createRef();
    this.rowRefs = MOBILE_ROWS.map((row) => createRef());
    this.cardQueue = {};
    this.throttledScroll = throttle(this.scrollHandler, 100);

    this.state = {
      visibleRows: [],
      loaded: false,
      x: 0,
      newRows: [],
      filteredCards: [],
      unload: false,
      direction: "none",
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.throttledScroll, false);
    const { instructionsSeen, setInstructionsSeen } = this.context;
    if (!instructionsSeen) {
      Cookies.set(COOKIE.instructions.name, COOKIE.instructions.value, {
        secure: true,
        HTTPOnly: false,
      });

      setTimeout(() => {
        setInstructionsSeen(true);
        this.initialLoad();
      }, 3000);
    } else {
      this.initialLoad();
    }
    if (this.props.filters.category || this.props.filters.contentType) {
      this.applyFilters(this.props.filters);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.filters !== this.props.filters) {
      this.applyFilters(this.props.filters);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.throttledScroll, false);
  }

  initialLoad = () => {
    const { viewColumns } = this.props.gridData;
    const cols = viewColumns % 2 === 0 ? viewColumns - 1 : viewColumns;
    const { cardData, priorityCardData } = this.context;
    this.cardQueue = new MobileCardQueue(cardData, priorityCardData, cols);

    // Ensure that the routing "scroll to top" happens before we set the initial scroll position
    // Easier and faster than writing custom logic on gatsby-browser's shouldUpdateScroll
    setTimeout(() => {
      this.setInitialScrollPosition();
    }, 100);
  };

  setInitialScrollPosition = () => {
    const { viewHeight } = this.props.gridData;
    const tenthRow = DIMENSIONS.height * (MOBILE_ROWS.length / 2);
    const verticalCenter = tenthRow - viewHeight / 2 + DIMENSIONS.gutter;

    this.setState({ unload: false }, () => {
      window.scrollTo(0, verticalCenter);
      const visibleRows = this.getVisibleRows();
      this.cardQueue.setInitialRows(visibleRows);

      this.setState({
        visibleRows,
        loaded: true,
      });
    });
  };

  applyFilters = (filters) => {
    const { cardData } = this.context;
    this.setState(
      {
        visibleRows: [],
        x: 0,
        filteredCards: [],
        unload: true,
        loaded: false,
      },
      () => {
        this.cardQueue.clearQueue();
        if (!filters.category.length && !filters.contentType.length) {
          this.initialLoad();
        } else {
          const filteredCardData = filterCards(filters, cardData);
          if (filteredCardData.length) {
            this.setState({ filteredCards: filteredCardData }, () => {
              window.scrollTo(0, this.wrapper.current.offsetHeight / 4);
            });
          }
        }
      }
    );
  };

  isRowVisible = (rowIdx) => {
    const el = this.rowRefs[rowIdx].current;
    const rect = el && el.getBoundingClientRect();

    return el && rect.top < window.innerHeight && rect.bottom >= 0;
  };

  getVisibleRows = () => {
    return MOBILE_ROWS.reduce((acc, currentCard, idx) => {
      if (this.isRowVisible(idx)) {
        acc.push(idx);
      }
      return acc;
    }, []);
  };

  swipe = (direction) => {
    const { visibleRows } = this.state;
    visibleRows.forEach((rowIdx) => {
      if (direction === "left") {
        this.cardQueue.swipeLeft(rowIdx);
      } else {
        this.cardQueue.swipeRight(rowIdx);
      }
    });
  };

  scrollHandler = () => {
    const { visibleRows } = this.state;
    const newVisibleRows = this.getVisibleRows();

    if (
      visibleRows[0] !== newVisibleRows[0] ||
      visibleRows.length !== newVisibleRows.length
    ) {
      const newRows = newVisibleRows.filter(
        (row) => !visibleRows.includes(row)
      );
      this.setState({
        visibleRows: newVisibleRows,
        direction: "none",
        newRows,
      });
    }
  };

  onSwiping = (e) => {
    if (e.dir === "Right" || e.dir === "Left") {
      e.event.preventDefault();
      this.setState({ x: e.deltaX });
    } else {
      // Hide header on vertical
      const { setVisibleHeader } = this.props;
      if (setVisibleHeader) {
        setVisibleHeader(false);
      }
    }
  };

  onSwiped = (e) => {
    const { x } = this.state;
    const SWIPE_BREAKPOINT = 0;
    if ((e.dir === "Left" || e.dir === "Right") && x) {
      e.event.preventDefault();
      if (Math.abs(e.deltaX) >= SWIPE_BREAKPOINT) {
        this.swipe(e.dir.toLowerCase());
      }
      this.setState({ x: 0 });
    } else {
      const { setVisibleHeader } = this.props;
      setTimeout(function () {
        // Bring header back
        if (setVisibleHeader) {
          setVisibleHeader(true);
        }
      }, 500);
    }
  };

  render() {
    const { carouselQueues } = this.cardQueue;
    const { instructionsSeen } = this.context;

    const {
      loaded,
      visibleRows,
      x,
      newRows,
      filteredCards,
      unload,
      direction,
    } = this.state;
    return (
      <Wrapper ref={this.wrapper}>
        {!unload ? (
          <Swipeable onSwiped={this.onSwiped} onSwiping={this.onSwiping}>
            {MOBILE_ROWS.map((rowKey, i) => (
              <Row
                key={rowKey}
                ref={this.rowRefs[i]}
                numCols={this.props.gridData.viewColumns}
              >
                {loaded && visibleRows.includes(i) ? (
                  <GridCarousel
                    cards={carouselQueues[i]}
                    direction={direction}
                    row={i}
                    x={x}
                    key={`cards-${rowKey}`}
                    newRows={newRows}
                  />
                ) : null}
              </Row>
            ))}
          </Swipeable>
        ) : filteredCards.length ? (
          <FilteredMobile cards={filteredCards} />
        ) : instructionsSeen ? (
          <NoResults
            welcome={{
              hint: this.props.emptyGridHint,
              title: this.props.emptyGridTitle,
              showCta: false,
            }}
            fill={false}
          />
        ) : null}
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  width: 100vw;
  overflow: hidden;
  animation: blur 0.2s linear;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @keyframes blur {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const Row = styled.div`
  height: ${DIMENSIONS.height + DIMENSIONS.gutter}px;
  width: ${DIMENSIONS.width * 3}px;
  transform: translateX(-${DIMENSIONS.gutter}px);
  display: flex;
`;

const scaleUp = keyframes`
  0% {
    transform: scale(0), translate(-50%, -50%);
    opacity: .2;
  }

  100% {
    transform: scale(1), translate(-50%, -50%);
    opacity: 1;
  }
`;

const NoResults = styled(TheGutLifePreview)`
  animation: ${scaleUp} 0.5s cubic-bezier(0.47, 0, 0.37, 1);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  transform-origin: center center;
`;

MobileGrid.contextType = GridContext;

MobileGrid.propTypes = {
  gridData: PropTypes.object.isRequired,
  setVisibleHeader: PropTypes.func,
  filters: PropTypes.object,
  emptyGridHint: PropTypes.string.isRequired,
  emptyGridTitle: PropTypes.string.isRequired,
};

export default MobileGrid;
