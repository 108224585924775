import React from "react";
import PropTypes from "prop-types";
import { DIMENSIONS as GRID_CARD_DIMENSIONS } from "./constants";
import GridCard from "./GridCard";
import DraggableWrapper from "./DraggableWrapper";
class FilteredDesktop extends React.Component {
  constructor(props) {
    super();
    const { viewColumns } = props.gridData;
    this.state = {
      offsetX: 0,
      offsetY: 0,
      visibleCards: {},
      rows: [[]],
    };

    this.unitsPerRow = viewColumns - 4;

    this.emptyRow = Array(viewColumns)
      .fill()
      .map(() => ({
        empty: true,
      }));
  }

  componentDidMount() {
    this.load();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.cards !== this.props.cards) {
      this.load();
    }
  }

  load = () => {
    const { cards } = this.props;
    const { viewColumns, viewRows } = this.props.gridData;

    const cardRows = this.props.cards.reduce(
      (rows, card, idx) => {
        const rowIdx = rows.length - 1;
        rows[rowIdx].push({ cardData: card });
        if (card.cardHighlighted) {
          rows[rowIdx].push("placeholder");
        }
        if (
          rows[rowIdx].length >= this.unitsPerRow &&
          idx !== cards.length - 1
        ) {
          rows.push([]);
        }
        return rows;
      },
      [[]]
    );

    // Add padding to each row edges
    cardRows.forEach((row, idx) => {
      const paddedRow = this.padWithEmpty(
        row,
        row.length,
        viewColumns,
        { empty: true },
        true
      );
      cardRows[idx] = [...paddedRow];

      // Fill if padding rows are necessary
      const paddedRows = this.padWithEmpty(
        cardRows,
        cardRows.length,
        viewRows,
        this.emptyRow,
        false
      );

      if (!paddedRows[0].every((card) => card.empty)) {
        paddedRows.unshift(this.emptyRow);
      }
      if (!paddedRows[paddedRows.length - 1].every((card) => card.empty)) {
        paddedRows.push(this.emptyRow);
      }

      this.setState({ rows: paddedRows }, () => {
        this.setInitialOffset();
      });
    });
  };

  padWithEmpty = (arr, len, limit, filler, leftFirst = true) => {
    const items = [...arr];
    if (len < limit) {
      const padding = limit - len;
      const side = leftFirst ? 1 : 0;
      for (let i = 0; i <= padding - 1; i++) {
        if (i % 2 === side) {
          // second
          items.unshift(filler);
        } else {
          // first
          items.push(filler);
        }
      }
    }
    return items;
  };

  onDragHandler = (deltaX, deltaY) => {
    const { visibleCards, offsetX, offsetY, rows } = this.state;
    const { gridData } = this.props;
    const { viewHeight, viewWidth } = gridData;
    let newOffsetX = offsetX + deltaX;
    let newOffsetY = offsetY + deltaY;

    const numRows = rows.length;
    const numCols = rows[0].length;
    const verticalOffset =
      (numRows - 0.5) * GRID_CARD_DIMENSIONS.height - viewHeight;
    const horizontalOffset =
      (numCols - 0.5) * GRID_CARD_DIMENSIONS.width - viewWidth;

    const topEdge = newOffsetY >= -(GRID_CARD_DIMENSIONS.height / 2);
    const leftEdge = newOffsetX >= -(GRID_CARD_DIMENSIONS.width / 2);
    const botEdge = newOffsetY <= -verticalOffset;
    const rightEdge = newOffsetX <= -horizontalOffset;

    if (topEdge || leftEdge || botEdge || rightEdge) {
      return;
    }

    const keys = Object.keys(visibleCards);
    const updatedCards = keys.reduce((cards, key) => {
      const card = visibleCards[key];
      if (!card) {
        return cards;
      }
      card.x += deltaX;
      card.y += deltaY;
      return { ...cards, [key]: card };
    }, {});
    this.setState({
      visibleCards: updatedCards,
      offsetX: newOffsetX,
      offsetY: newOffsetY,
    });
  };

  getCardPosition = (col, row) => {
    const { offsetX, offsetY } = this.state;
    const x = col * GRID_CARD_DIMENSIONS.width + offsetX;
    const y = row * GRID_CARD_DIMENSIONS.height + offsetY;
    return [Math.round(x), Math.round(y)];
  };

  updateGrid = () => {
    const { rows } = this.state;
    const newCards = {};

    for (let row = 0; row < rows.length; row++) {
      for (let col = 0; col < rows[row].length; col++) {
        const cell = `${col}:${row}`;
        const [x, y] = this.getCardPosition(col, row);
        const card = rows[row][col];
        newCards[cell] = { card, x, y };
      }
    }
    this.setState({ visibleCards: newCards });
  };

  setInitialOffset = () => {
    const { rows } = this.state;
    const { gridData } = this.props;
    const { viewWidth, viewHeight } = gridData;
    const numRows = rows.length;
    const numCols = rows[0].length;

    const verticalOffset =
      (numRows * GRID_CARD_DIMENSIONS.height - viewHeight) / 2;
    const horizontalOffset =
      (numCols * GRID_CARD_DIMENSIONS.width - viewWidth) / 2 - 20;
    this.setState(
      {
        offsetX: -horizontalOffset,
        offsetY: -verticalOffset,
      },
      () => {
        this.updateGrid();
      }
    );
  };

  render() {
    const { visibleCards } = this.state;
    return (
      <DraggableWrapper onDrag={this.onDragHandler}>
        {Object.keys(visibleCards).map((cell, idx) => {
          const { card, x, y } = visibleCards[cell];

          return (
            card !== "placeholder" && (
              <GridCard {...card} x={x} y={y} key={cell} />
            )
          );
        })}
      </DraggableWrapper>
    );
  }
}

FilteredDesktop.propTypes = {
  cards: PropTypes.arrayOf(PropTypes.object),
  gridData: PropTypes.object,
};

export default FilteredDesktop;
