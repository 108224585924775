export default class CardCache {
  constructor() {
    this.cache = {};
  }

  getIdFromCachedCell = (cell) => {
    return this.cache[cell] || null;
  };

  cacheCell = (cell, id) => {
    this.cache[cell] = id;
  };

  clearCache = () => {
    this.cache = {};
  };
}
