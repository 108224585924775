import React, { useState, useRef, useEffect, useContext } from "react";
import { TweenLite } from "gsap";
import PropTypes from "prop-types";
import styled from "styled-components";
import { GridContext } from "./GridContext";
import PageContext from "src/stores/Page";
import throttle from "src/utils/throttle";

/**
 * Handles drag and drop behavior. From its content moving to the transition
 * used when a simulated drag event happens
 */
const DraggableWrapper = ({ onDrag, children }) => {
  const [isDragging, setIsDragging] = useState(false);
  const { setIsMoving } = useContext(GridContext);
  const {
    visibleHeader: [, setVisibleHeader],
  } = useContext(PageContext);
  const [lastX, setLastX] = useState(0);
  const [lastY, setLastY] = useState(0);
  const [tween, setTween] = useState(null);
  const [timer, setNewTimer] = useState(null);
  const el = useRef(null);

  const onDragStartHandler = (evt) => {
    const e = evt.type === "touchstart" ? evt.touches[0] : evt;
    setLastX(e.clientX);
    setLastY(e.clientY);
    setIsDragging(true);
  };

  const onDragHandler = (evt) => {
    const isWheel = evt.type === "wheel";
    const isMouse = evt.type === "mousemove";
    if (lastX === null || lastY === null) {
      return true;
    }
    const e = evt.type === "touchmove" ? evt.touches[0] : evt;

    if (isMouse) {
      const xUp = e.clientX;
      const yUp = e.clientY;

      const xDiff = Math.abs(lastX - xUp);
      const yDiff = Math.abs(lastY - yUp);

      // propagate click if there was no drag on mouse use
      if (xDiff < 1 && yDiff < 1) {
        return true;
      }
    }

    if (isDragging || isWheel) {
      setIsMoving(true);
      evt.preventDefault();

      if (timer !== null) {
        setNewTimer(null);
        clearTimeout(timer);
      }
      setNewTimer(
        setTimeout(
          function () {
            // Bring header back
            endDrag();
          },
          isWheel ? 100 : 500
        )
      );

      if (setVisibleHeader) {
        setVisibleHeader(false);
      }

      const wheelDeltaX = e.wheelDeltaX || e.deltaX;
      const wheelDeltaY = e.wheelDeltaY || e.deltaY;

      const xDelta = isWheel ? wheelDeltaX / 5 : e.clientX - lastX;
      const yDelta = isWheel ? wheelDeltaY / 5 : e.clientY - lastY;
      const velocity = Math.abs(xDelta * yDelta);

      if (velocity > 50 && !isWheel) {
        const v = { x: xDelta * 0.5, y: yDelta * 0.5 };
        if (tween) {
          tween.kill();
        }
        setTween(
          TweenLite.to(v, 0.5, {
            x: 0,
            y: 0,
            onUpdate: () => {
              onDrag(v.x, v.y);
            },
          })
        );
      }

      onDrag(xDelta, yDelta);
      setLastX(e.clientX);
      setLastY(e.clientY);
    }
  };

  const onBrowserLeave = (e) => {
    const evt = e || window.event;
    const viewportWindow = evt.relatedTarget || evt.toElement;
    if (!viewportWindow || viewportWindow.nodeName === "HTML") {
      // Cancel drag event if mouse leaves browser window
      endDrag();
      setLastX(e.clientX);
      setLastY(e.clientY);
    }
  };

  const endDrag = () => {
    setIsDragging(false);
    setIsMoving(false);
    if (setVisibleHeader) {
      setVisibleHeader(true);
    }
  };

  const onScrollThrottled = (e) => throttle(onDragHandler(e), 100);

  const preventDrag = (e) => e.preventDefault();

  useEffect(() => {
    el.current.addEventListener("mousedown", onDragStartHandler, true);
    el.current.addEventListener("mousemove", onDragHandler, true);
    el.current.addEventListener("mouseup", endDrag, true);
    window.addEventListener("mouseout", onBrowserLeave, true);
    el.current.addEventListener("drag", preventDrag, true);
    el.current.addEventListener("touchstart", onDragStartHandler, true);
    el.current.addEventListener("touchmove", onDragHandler, true);
    el.current.addEventListener("touchend", endDrag, true);
    el.current.addEventListener("wheel", onScrollThrottled, true);

    return () => {
      el.current.removeEventListener("mousedown", onDragStartHandler, true);
      el.current.removeEventListener("mousemove", onDragHandler, true);
      el.current.removeEventListener("mouseup", endDrag, true);
      window.removeEventListener("mouseout", onBrowserLeave, true);
      el.current.removeEventListener("drag", preventDrag, true);
      el.current.removeEventListener("touchstart", onDragStartHandler, true);
      el.current.removeEventListener("touchmove", onDragHandler, true);
      el.current.removeEventListener("touchend", endDrag, true);
      el.current.removeEventListener("wheel", onScrollThrottled, true);
    };
  });

  return <Wrapper ref={el}>{children}</Wrapper>;
};

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  cursor: grab;
  user-select: none;
  overflow: hidden;

  &:active {
    cursor: grabbing;
  }
`;

DraggableWrapper.propTypes = {
  onDrag: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default DraggableWrapper;
