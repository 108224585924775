import { TYPES } from "src/atoms/Picture";

export default (obj, type) => {
  switch (type) {
    case TYPES.fluid: {
      const { src } = obj[type];
      const { width, height } = obj.file.details.image;
      return { width, height, src };
    }
    default: {
      const { width, height, src } = obj[type];
      return { width, height, src };
    }
  }
};
