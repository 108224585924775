import React, { createContext, useState, useEffect } from "react";
import PropTypes from "prop-types";

export const GridContext = createContext();

const GridContextProvider = ({
  children,
  CardComponent,
  cardData,
  priorityCardData,
  hint,
  title,
  filters,
  instructionsSeen,
  setInstructionsSeen,
}) => {
  const [stateFilters, setStateFilters] = useState(filters);
  const [isMoving, setIsMoving] = useState(false);

  useEffect(() => {
    setStateFilters(filters);
  }, [filters]);

  return (
    <GridContext.Provider
      value={{
        CardComponent,
        cardData,
        hint,
        title,
        priorityCardData,
        isMoving,
        setIsMoving,
        instructionsSeen,
        setInstructionsSeen,
        filters: stateFilters,
      }}
    >
      {children}
    </GridContext.Provider>
  );
};

GridContextProvider.propTypes = {
  CardComponent: PropTypes.elementType,
  hint: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  cardData: PropTypes.array.isRequired,
  priorityCardData: PropTypes.array.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  filters: PropTypes.object,
  instructionsSeen: PropTypes.bool,
  setInstructionsSeen: PropTypes.func,
};

export default GridContextProvider;
