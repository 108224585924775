import React from "react";

import SVG from "../SVG";

export default SVG(() => (
  <svg viewBox="0 0 32 32" fill="none">
    <path
      d="M23.026 8H8.064a1 1 0 0 0-.784 1.62l6.328 8.01a1 1 0 0 1 .215.62V26s2.528 0 3.912-2.365V18.23c0-.213.068-.42.194-.59l5.904-8.049A1 1 0 0 0 23.026 8z"
      stroke="#02BA44"
      strokeWidth={2}
    />
  </svg>
));
