import React from "react";
import PropTypes from "prop-types";
import GridCard from "./GridCard";

const GridCarousel = ({ cards, row, direction, x, newRows }) => {
  const cardIdxArr = cards.map((card) => card.id);
  return (
    <>
      {cards.map((card, idx) => {
        const animate =
          (!(direction === "left" && idx !== cards.length - 1) &&
            !(direction === "right" && idx !== 0)) ||
          !(direction === "none" && newRows.includes(row));

        const timesInRow = cardIdxArr.filter((c) => c === card.id).length;

        return (
          <GridCard
            mobile
            cardData={card}
            x={-x}
            y={0}
            key={`row-${row}-card-${card.id}-${timesInRow === 1 ? 1 : idx}`}
            loadAnimated={animate}
          />
        );
      })}
    </>
  );
};

GridCarousel.propTypes = {
  cards: PropTypes.array.isRequired,
  row: PropTypes.number.isRequired,
  direction: PropTypes.string.isRequired,
  x: PropTypes.number.isRequired,
  newRows: PropTypes.array.isRequired,
};

export default GridCarousel;
