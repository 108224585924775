import { DIMENSIONS as CARD_DIMENSIONS } from "src/molecules/Card";

export const DIMENSIONS = Object.freeze({
  width: CARD_DIMENSIONS.width + CARD_DIMENSIONS.gutter,
  height: CARD_DIMENSIONS.height + CARD_DIMENSIONS.gutter,
  doubleWidth: CARD_DIMENSIONS.width * 2 + CARD_DIMENSIONS.gutter * 2,
});

export const COOKIE = Object.freeze({
  instructions: {
    name: "gutGridInstructionsSeen",
    value: 1,
  },
});

export const CELL_NEIGHBOURS = Object.freeze([
  [-1, -1], // upper left
  [0, -1], // up
  [1, -1], // upper right
  [1, 0], // right
  [-1, 1], // bottom left
  [0, 1], // down
  [1, 1], // bottom right
  [-1, 0], // left
]);

export const DESKTOP_CENTER_NEIGHBOURS = Object.freeze([
  [-1, -1], // upper left corner
  [0, -1], // up left
  [1, -1], // up right
  [2, -1], // up right corner
  [2, 0], // right
  [-1, 1], // bottom left corner
  [0, 1], // bottom left
  [1, 1], // bottom right
  [2, 1], // bottom right corner
  [-1, 0], // left
]);

export const MOBILE_ROWS = [
  "a",
  "b",
  "c",
  "d",
  "e",
  "f",
  "g",
  "h",
  "i",
  "j",
  "k",
  "l",
  "m",
  "n",
  "o",
  "p",
  "q",
  "r",
  "s",
  "t",
];
